import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SocialLinks from '../components/SocialLinks';
const IndexPage = () => (
  <Layout>
    <Header textClass="text-black" />
    <section className="contact-us-page">
      <h2 className="text-center">Contact Us</h2>
      <p className="text-center mx-auto">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt ut labore
      </p>
    </section>
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
